<template>
    <section id="Dataservices" class="services">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Data Analytics</h2>
           </div>

        <div class="row" style="padding-top:30px">
          <div class="col-md-4 d-flex align-items-stretch" v-for="(obj, key) in mobileappJSON" :key="key">
            <div class="icon-box">
            <Image :fpath=" obj.path " class="serviceimage"/>
              <h4>{{ obj.header }}</h4>
               <h6 style="font-size:14px">{{ obj.subtitle }}</h6>
                <p>{{ obj.caption }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>
</template>
<script>
import Image from '@/components/Image.vue'
export default {
    components: {
    Image
  },
  data: () =>({
    mobileappJSON: [
      {
        path: 'services/Data.jpg',
        header: 'SKILLED PROFESSIONALS',
        subtitle: 'Your Data is in good hands',
        caption: 'Our skilled Data analysts work to ensure that the data in question would yield results that are full-fledged to make decisions, test hypotheses and answer queries.'
      },
      {
        path: 'services/Planning.jpg',
        header: 'EFFECTIVE PLANNING',
        subtitle: 'Process-oriented analysis',
        caption: 'We undertake complete data analytic process before analysing. It involves processing, organizing and visualizing to be able to support user decisions.'
      },
      {
        path: 'services/Goal.jpg',
        header: 'ACHIEVING SET GOALS',
        subtitle: 'We have got you covered',
        caption: 'The result of analysis is focused on achieving our client’s goals. It is done through a step by step process that involves preparing, developing, analysing and finally testing.'
      }
    ]

  })
    
}
</script>
<style>
img.serviceimage {
    width: 100%;
    padding-bottom: 30px;
}
.services .icon-box {
  margin-bottom: 20px;
  padding: 20px 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
section#Dataservices {
    padding: 50px 30px;
}
.services .icon-box i {
  float: left;
  color: #f6b024;
  font-size: 40px;
  line-height: 0;
}

.services .icon-box h4 {
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 16px;
}

.services .icon-box h4 {
  color: #05579e;
  transition: 0.3s;
}

.services .icon-box h4 a:hover {
  color: #0880e8;
}

.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
@media (max-width: 998px) and (min-width: 452px)  {
.services .icon-box h4 {
  font-size: 14px !important;
  line-height: 20px;
}
}
</style>