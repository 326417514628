<template>
    <Header/>
    <Dataheader/>
    <Dataservice/>
    <DataSection/>
    <Footer/>
</template>

<script>
// @ is an alias to /src

import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
import Dataheader from '@/components/dataheader.vue'
import Dataservice from '@/components/Dataservice.vue'
import DataSection from '@/components/DataSection.vue'


export default {
  components: {
     Header,
    Footer,
    Dataheader,
    Dataservice,
    DataSection
  }
}
</script>